import { OnboardingDataResponse, PostRegistrationOnboardingAction } from '@piccadilly-cloud/connect-platform-services';

import { paths } from 'src/routes/paths';

import { OnboardingFlowConfiguration, OnboardingFlowType, OnboardingStep, WelcomeDialogConfig } from './model';

const createOnboardingStep = (
  event: PostRegistrationOnboardingAction,
  options: Partial<OnboardingStep> = {},
): OnboardingStep => ({
  label: '',
  description: '',
  useExpand: false,
  isComplete: false,
  button: undefined,
  event,
  ...options,
});

const getOnboardingFlowSteps = (workspaceIndex: number): Record<PostRegistrationOnboardingAction, OnboardingStep> => ({
  [PostRegistrationOnboardingAction.CreateWorkspace]: createOnboardingStep(
    PostRegistrationOnboardingAction.CreateWorkspace, {
    label: 'Create your workspace',
    isComplete: true,
  }),
  [PostRegistrationOnboardingAction.CreateJobProfile]: createOnboardingStep(
    PostRegistrationOnboardingAction.CreateJobProfile, {
    label: 'Build a job profile',
    description: 'Next, set up your first job profile to customize an assessment.',
    useExpand: true,
    button: {
      label: 'Create job',
      href: paths.recruiter.workspace.jobs.profiles(workspaceIndex),
    },
  }),
  [PostRegistrationOnboardingAction.SelectPricingPlan]: createOnboardingStep(
    PostRegistrationOnboardingAction.SelectPricingPlan, {
    label: 'Select a plan',
    description: 'A subscription is required to invite candidates.',
    useExpand: true,
    button: {
      label: 'View plans',
      href: '', // TODO: Add route to pricing page once complete
    },
  }),
  [PostRegistrationOnboardingAction.ShareAssessment]: createOnboardingStep(
    PostRegistrationOnboardingAction.ShareAssessment, {
    label: 'Share assessment',
    description: 'Use your credits to start inviting candidates.',
    useExpand: true,
  }),
});

export const getOnboardingFlowConfig = (index: number): OnboardingFlowConfiguration => {
  const steps = getOnboardingFlowSteps(index);

  return {
    [OnboardingFlowType.PURCHASE]: [
      steps[PostRegistrationOnboardingAction.CreateWorkspace],
      steps[PostRegistrationOnboardingAction.CreateJobProfile],
      steps[PostRegistrationOnboardingAction.SelectPricingPlan],
    ],
    [OnboardingFlowType.PREPURCHASE]: [
      steps[PostRegistrationOnboardingAction.CreateWorkspace],
      steps[PostRegistrationOnboardingAction.CreateJobProfile],
      steps[PostRegistrationOnboardingAction.ShareAssessment],
    ],
  };
};

export const defaultWelcomeDialogConfig: WelcomeDialogConfig = {
  header: "You're in! We're excited you are here.",
  body: `Begin by creating a job profile. You'll be able to customize requirements 
  and define critical soft skills, invite candidates, view completed assessment 
  scorecards in your dashboard, and more.`,
  ctaLine: "Ready to find your Best Fit candidates? Let's begin!",
  buttonText: 'Start',
};

export const welcomeDialogConfigs: { [key in OnboardingFlowType]: WelcomeDialogConfig } = {
  [OnboardingFlowType.PURCHASE]: {
    ...defaultWelcomeDialogConfig,
    body: `As part of your first TEQ Connect experience, you now have access to the 
    following features, at no cost to you:`,
    features: [
      { title: 'One job profile' },
      { title: 'Your own dedicated workspace' },
      { title: 'An additional workspace with sample jobs and candidates' },
    ],
    ctaLine: "Let's get started by creating your first job profile.",
    buttonText: 'Get started',
  },
  [OnboardingFlowType.PREPURCHASE]: {
    ...defaultWelcomeDialogConfig,
  },
};

export const defaultOnboardingData: OnboardingDataResponse = {
  id: '',
  vendorId: '',
  preRegistration: {
    q1: '',
    q2: '',
    q3: '',
  },
  postRegistration: {
    completedActions: [],
    isComplete: false,
  },
};

export const defaultOnboardingStep = getOnboardingFlowSteps(0)[PostRegistrationOnboardingAction.CreateWorkspace];
