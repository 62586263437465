import { ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { LoadingScreen } from 'src/components/loading-screen';
import { useToast } from 'src/hooks';

import { useSessionContext } from '../useSessionContext';

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized, account } = useSessionContext();
  const toast = useToast();

  useEffect(() => {
    if (isAuthenticated) {
      const isAssessment = window.location.pathname && window.location.pathname.includes('/assessment/');
      const isRecruiterLike = account.accountRole === 'RECRUITER' || account.accountRole === 'WORKSPACE_ADMIN';
      if (isAssessment && isRecruiterLike) {
        toast.info('Please sign out to view');
      }
    }
  }, [
    account.accountRole,
    isAuthenticated,
    toast,
  ]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
}
