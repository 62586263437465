import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { LoadingScreen } from 'src/components/loading-screen';
import AuthGuard from 'src/contexts/session/guards/auth-guard';
import WorkspaceAdminGuard from 'src/contexts/session/guards/workspace-admin-guard';
import PrintClassicLayout from 'src/layouts/print/classic';
// import DashboardLayoutUnjustified from 'src/layouts/workspace-unjustified';
import DashboardLayout from 'src/layouts/v2/dashboard/layout';
import Payments from 'src/pages/payments';

// ----------------------------------------------------------------------

const Dashboard = lazy(() => import('src/pages/dashboard'));
const BillingDashboard = lazy(
  () => import('src/pages/billing-dashboard/billingDashboard'),
);
const Profile = lazy(() => import('src/pages/user-account/profile'));
const AppSettings = lazy(() => import('src/pages/app/app-settings'));

const WorkspaceCreatorPage = lazy(
  () => import('src/pages/recruiter/workspace/workspace-creator-page'),
);
const WorkspaceDashboardPage = lazy(
  () => import('src/pages/recruiter/workspace/workspace-dashboard'),
);
const WorkspaceBuilderPage = lazy(
  () => import('src/pages/recruiter/workspace/workspace-builder-page'),
);
const WorkspacePreviewPage = lazy(
  () => import('src/pages/recruiter/workspace/workspace-preview-page'),
);
const WorkspaceMembersPage = lazy(
  () => import('src/pages/recruiter/workspace/workspace-member-page'),
);
const WorkspaceMemberViewPage = lazy(
  () => import('src/pages/recruiter/workspace/workspace-member-view-page'),
);
const WorkspaceSettingsPage = lazy(
  () => import('src/pages/recruiter/workspace/workspace-settings-page'),
);
const WorkspaceInvitePage = lazy(
  () => import('src/pages/recruiter/workspace/workspace-invite-page'),
);
const WorkspaceMemberInvitationViewPage = lazy(
  () =>
    import(
      'src/pages/recruiter/workspace/workspace-member-invitation-view-page'
    ),
);

const CandidateAllCandidatesPage = lazy(
  () => import('src/pages/recruiter/candidates/candidates'),
);
const CandidateScorecardPage = lazy(
  () => import('src/pages/recruiter/candidates/candidate-scorecard'),
);
const CandidateScorecardPrintPage = lazy(
  () => import('src/pages/recruiter/candidates/candidate-scorecard-print'),
);

const ListingsPage = lazy(
  () => import('src/pages/recruiter/listings/listings-table'),
);
const ListingEditorPage = lazy(
  () => import('src/pages/recruiter/listings/listing-editor'),
);
const ProfileSelectionPage = lazy(
  () => import('src/pages/recruiter/listings/profile-selection'),
);

const ListingDashboardPage = lazy(
  () => import('src/pages/recruiter/listing-dashboard/listing-dashboard'),
);
const ListingDashboardCandidateScorecardPage = lazy(
  () =>
    import('src/pages/recruiter/listing-dashboard/listing-dashboard-candidate'),
);
const ListingDashboardCandidateComparePage = lazy(
  () =>
    import('src/pages/recruiter/listing-dashboard/listing-dashboard-compare'),
);

// ----------------------------------------------------------------------

export const workspaceAdminRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <WorkspaceAdminGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </WorkspaceAdminGuard>
      </AuthGuard>
    ),
    children: [{ path: 'dashboard', element: <Dashboard />, index: true }],
  },
  {
    path: '',
    element: (
      <AuthGuard>
        <WorkspaceAdminGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </WorkspaceAdminGuard>
      </AuthGuard>
    ),
    children: [
      // { path: 'dashboard', element: <Dashboard />, index: true, },
      { path: 'profile', element: <Profile />, index: true },
      { path: 'billingDashboard', element: <BillingDashboard />, index: true },
      { path: 'app-settings', element: <AppSettings />, index: true },
      { path: 'workspace/new', element: <WorkspaceCreatorPage />, index: true },
      { path: 'payments', element: <Payments />, index: true },

      {
        path: 'recruiter/w/:workspaceIndex/workspace/builder',
        element: <WorkspaceBuilderPage />,
      },
      {
        path: 'recruiter/w/:workspaceIndex/workspace/builder/Preview',
        element: <WorkspacePreviewPage />,
      },
      {
        path: 'recruiter/w/:workspaceIndex/workspace/dashboard',
        element: <WorkspaceDashboardPage />,
      },
      {
        path: 'recruiter/w/:workspaceIndex/workspace/members',
        element: <WorkspaceMembersPage />,
      },
      {
        path: 'recruiter/w/:workspaceIndex/workspace/members/:accountId',
        element: <WorkspaceMemberViewPage />,
      },
      {
        path: 'recruiter/w/:workspaceIndex/workspace/members/invitation/:invitationId',
        element: <WorkspaceMemberInvitationViewPage />,
      },
      {
        path: 'recruiter/w/:workspaceIndex/workspace/settings',
        element: <WorkspaceSettingsPage />,
      },
      {
        path: 'recruiter/w/:workspaceIndex/workspace/invite',
        element: <WorkspaceInvitePage />,
      },

      {
        path: 'recruiter/w/:workspaceIndex/dashboard',
        element: <Dashboard />,
        index: true,
      },

      {
        path: 'recruiter/w/:workspaceIndex/members',
        element: <WorkspaceMembersPage />,
      },

      { path: 'recruiter/w/:workspaceIndex/jobs', element: <ListingsPage /> },
      {
        path: 'recruiter/w/:workspaceIndex/jobs/:listingId',
        element: <ListingEditorPage />,
      },
      {
        path: 'recruiter/w/:workspaceIndex/job/create',
        element: <ProfileSelectionPage />,
      },

      {
        path: 'recruiter/w/:workspaceIndex/dashboard/job/:listingId',
        element: <ListingDashboardPage />,
      },
      {
        path: 'recruiter/w/:workspaceIndex/dashboard/job/:listingId/candidate/:teqScoreId',
        element: <ListingDashboardCandidateScorecardPage />,
      },
      { path: 'recruiter/w/:workspaceIndex/dashboard/job/:listingId', element: <ListingDashboardPage /> },
      {
        path: 'recruiter/w/:workspaceIndex/dashboard/job/:listingId/candidate/:teqScoreId',
        element: <ListingDashboardCandidateScorecardPage />,
      },
      {
        path: 'recruiter/w/:workspaceIndex/dashboard/job/:listingId/compare',
        element: <ListingDashboardCandidateComparePage />,
      },

      {
        path: 'recruiter/w/:workspaceIndex/candidates',
        element: <CandidateAllCandidatesPage />,
      },
      {
        path: 'recruiter/w/:workspaceIndex/candidates/results/:teqScoreId',
        element: <CandidateScorecardPage />,
      },
    ],
  },
  {
    path: '',
    element: (
      <AuthGuard>
        <WorkspaceAdminGuard>
          <PrintClassicLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </PrintClassicLayout>
        </WorkspaceAdminGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: 'recruiter/w/:workspaceIndex/candidates/results/:teqScoreId/print',
        element: <CandidateScorecardPrintPage />,
      },
    ],
  },
];
