import services from '@piccadilly-cloud/connect-platform-services';

import { Reducer, createContext, useEffect, useReducer } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { useWorkspace } from 'src/hooks/use-workspace';
import useLogger from 'src/utils/useReducerLogger';

import { ContextMutations } from '../model';
import { useSessionContext } from '../session';

import {
  currentStep_draft,
  getEditorStepMap,
  getNextStepInOrder,
  getPreviousStepInOrder,
  initListing,
  initProfile,
} from './helpers';
import {
  ListingEditorFlowAction,
  ListingEditorFlowDContextDispatch,
  ListingEditorFlowDispatch,
  ListingEditorFlowState,
} from './model';

type S = ListingEditorFlowState;

const initState = (): S => ({
  currentStep: currentStep_draft,
  dispatch: listingEditorFlowDispatch(() => { }),
  isLoading: false,
  isSubmittedForReview: false,
  listing: initListing(),
  profile: initProfile(),
});

const listingEditorFlowMutations: ContextMutations<S, ListingEditorFlowAction> = {
  'listingEditorFlow/SET_DISPATCH': (state, { dispatch }) => ({
    ...state,
    dispatch,
  }),

  'listingEditorFlow/SET_IS_LOADING': (state, { isLoading }) => ({
    ...state,
    isLoading,
  }),

  'listingEditorFlow/SET_IS_SUBMITTED_FOR_REVIEW': (state, { isSubmittedForReview }) => ({
    ...state,
    isSubmittedForReview,
  }),

  'listingEditorFlow/SET_LISTING': (state, { newListing }) => ({
    ...state,
    listing: newListing,
  }),

  'listingEditorFlow/SET_PROFILE': (state, { newProfile }) => ({
    ...state,
    profile: newProfile,
  }),

  'listingEditorFlow/SET_STEP': (state, { step }) => {
    if (!step) {
      return state;
    }
    return {
      ...state,
      currentStep: step,
    };
  },
};

const reducer = (state: S, action: ListingEditorFlowAction): S =>
  listingEditorFlowMutations[action.type](state, action.payload as any) ?? state;

const listingEditorFlowDispatch: ListingEditorFlowDContextDispatch = (dispatch): ListingEditorFlowDispatch => ({
  'listingEditorFlow/deleteListing': async ({
    token,
    workspaceId,
    listingId,
  }) => {
    try {
      await services.edge.job.listing.deleteListing(workspaceId, listingId)({ token });
    } catch (error) {
      console.error(error);
    }
  },

  'listingEditorFlow/onNextStep': async ({ currentStep }) => {
    const nextStep = getNextStepInOrder(currentStep.stepName);
    window.scrollTo(0, 0);
    dispatch({
      type: 'listingEditorFlow/SET_STEP',
      payload: {
        step: nextStep,
      },
    });
  },

  'listingEditorFlow/onPreviousStep': async ({ currentStep }) => {
    const previousStep = getPreviousStepInOrder(currentStep.stepName);
    window.scrollTo(0, 0);
    dispatch({
      type: 'listingEditorFlow/SET_STEP',
      payload: {
        step: previousStep,
      },
    });
  },

  'listingEditorFlow/publishListing': async ({
    listingId,
    token,
    workspaceId,
  }) => {
    try {
      await services.edge.job.listing.updateStatus_publish(workspaceId, listingId)({ token });
    } catch (error) {
      console.error(error);
    }
  },

  'listingEditorFlow/setCurrentStep': async (stepId) => {
    const stepMap = getEditorStepMap();
    window.scrollTo(0, 0);

    dispatch({
      type: 'listingEditorFlow/SET_STEP',
      payload: {
        step: stepMap.get(stepId),
      },
    });
  },

  'listingEditorFlow/setIsLoading': async (isLoading) => {
    dispatch({
      type: 'listingEditorFlow/SET_IS_LOADING',
      payload: {
        isLoading,
      },
    });
  },

  'listingEditorFlow/submitListingForReview': async ({
    listingId,
    token,
    values,
    workspaceId,
  }) => {
    try {
      await services.edge.job.listing.updateStatus_review(workspaceId, listingId, values)({ token });
      dispatch({
        type: 'listingEditorFlow/SET_IS_SUBMITTED_FOR_REVIEW',
        payload: {
          isSubmittedForReview: true,
        },
      });
    } catch (error) {
      console.error(error);
    }
  },

  'listingEditorFlow/updateListing': async ({
    listingId,
    nextValues,
    token,
    workspaceId,
  }) => {
    try {
      await services.edge.job.listing.updateForEdit(workspaceId, listingId, nextValues)({ token });
      dispatch({
        type: 'listingEditorFlow/SET_LISTING',
        payload: {
          newListing: nextValues,
        },
      });
    } catch (error) {
      console.error(error);
    }
  },
});

interface ListingEditorFlowProviderProps {
  children: React.ReactNode;
}

export const ListingEditorFlowContext = createContext<S | null>(null);
export function ListingEditorFlowProvider({ children }: ListingEditorFlowProviderProps) {
  const [state, dispatch] = useReducer<Reducer<S, ListingEditorFlowAction>>(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    process.env.REACT_APP_DEBUG_REDUX === 'true' ? useLogger(reducer) : reducer,
    initState(),
  );

  useEffect(() => {
    dispatch({
      type: 'listingEditorFlow/SET_DISPATCH',
      payload: {
        dispatch: listingEditorFlowDispatch(dispatch),
      },
    });
  }, [dispatch]);

  const session = useSessionContext();
  const w = useWorkspace();
  const { listingId } = useParams<'listingId'>();

  const listingQuery = useQuery(
    [`listing-${listingId}`, {}],
    async () => {
      const data = await services.edge.job.listing.getForEdit(
        w.workspace.id,
        listingId || '',
      )({ token: session.token });
      return data;
    },
    { keepPreviousData: false, cacheTime: 1, staleTime: Infinity, refetchOnMount: 'always', enabled: listingId !== '' },
  );

  useEffect(() => {
    if (!listingQuery.data?.listing || !listingQuery.data?.profile) {
      return;
    }

    dispatch({ type: 'listingEditorFlow/SET_LISTING', payload: { newListing: listingQuery.data.listing } });
    dispatch({ type: 'listingEditorFlow/SET_PROFILE', payload: { newProfile: listingQuery.data.profile } });
  }, [listingQuery.data, state.listing]);

  useEffect(() => {
    state.dispatch['listingEditorFlow/setIsLoading'](listingQuery.isLoading);
  }, [listingQuery.isLoading, state.dispatch]);

  return (
    <ListingEditorFlowContext.Provider value={state}>
      {children}
    </ListingEditorFlowContext.Provider>
  );
}
