import services, { Session } from '@piccadilly-cloud/connect-platform-services';

const getAPIKey = async (session: Session): Promise<string> => {
  const data = await services.edge.transcription.getTranscriptionAPIKey()({ token: session.token });
  if (!data || !data.token) {
    throw new Error('no transcription API key found');
  }

  const decodedKey = atob(data.token);
  return decodedKey;
};

const transcription = {
  getAPIKey,
};

export default transcription;
