/* eslint-disable max-len */
import { LicenseInfo } from '@mui/x-license-pro';

import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import ReactGa from 'react-ga4';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider as SessionProvider } from 'src/contexts/session/SessionContext';

import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/third_party/embedly.min.css';

import App from './App';

ReactGa.initialize('G-137BQQ3GQR');

const tagManagerArgs = {
  gtmId: 'GTM-W9FF9N7G',
};

TagManager.initialize(tagManagerArgs);
LicenseInfo.setLicenseKey('999ac41a9ef8c8ec4cab6c5f57222c78Tz04MzQ3NyxFPTE3Mzg1MDczOTYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=');

const queryClient = new QueryClient();

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <SessionProvider>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </SessionProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>,
);
