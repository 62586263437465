import { useContext } from 'react';

import { ApplicationFlowContext } from './ApplicationFlowContext';

export const useApplicationFlowContext = () => {
  const context = useContext(ApplicationFlowContext);
  if (!context) {
    throw new Error('useApplicationFlowContext context must be use inside ApplicationFlowProvider');
  }

  return context;
};
