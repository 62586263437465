import { NavConfigProps, NavListProps } from 'src/components/nav-section';
import { useSessionContext } from 'src/contexts/session/useSessionContext';

import NavConfigAdmin from './nav-config-admin';
import { NavConfigCandidate } from './nav-config-candidate';
import NavConfigRecruiter from './nav-config-recruiter';
import { NavConfigVendorAdmin } from './nav-config-vendor-admin';
import { NavConfigWorkspaceAdmin } from './nav-config-workspace-admin';

interface ReturnType {
  data: {
    subheader: string;
    items: NavListProps[];
  }[];
  config?: NavConfigProps;
}

export function useNavConfig(): ReturnType {
  const { account } = useSessionContext();

  switch (account.accountRole) {
    case 'CANDIDATE':
      return {
        data: NavConfigCandidate(),
        config: {
          currentRole: 'candidate',
        },
      };
    case 'RECRUITER':
      return {
        data: NavConfigRecruiter(),
        config: {
          currentRole: 'recruiter',
          itemRadius: 8,
        },
      };
    case 'ADMIN':
      return {
        data: NavConfigAdmin(),
        config: {
          currentRole: 'admin',
        },
      };
    case 'WORKSPACE_ADMIN':
      return {
        data: NavConfigWorkspaceAdmin(),
        config: {
          currentRole: 'workspace_admin',
        },
      };
    case 'VENDOR_ADMIN':
      return {
        data: NavConfigVendorAdmin(),
        config: {
          currentRole: 'vendor_admin',
        },
      };
    default:
      return {
        data: [],
      };
  }
}
