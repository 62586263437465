import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';

import { ReactNode } from 'react';

import Iconify from 'src/components/iconify';
import piccIcon from 'src/utils/config/icon';

export interface CustomDialogEmits {
  close?: () => void;
  confirm?: () => void;
}

export interface CustomDialogProps extends Omit<DialogProps, 'onClose' | 'onConfirm'> {
  confirmText?: string;
  children: ReactNode;
  confirmDisabled?: boolean;
  emit?: CustomDialogEmits;
  fullWidthCancel?: boolean;
  fullWidthConfirm?: boolean;
  hideCancel?: boolean;
  hideConfirm?: boolean;
  hideTitle?: boolean;
  loading?: boolean;
  open: boolean;
}

const StyledDialogTitle = styled(DialogTitle)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&.MuiDialogTitle-root': {
    padding: '24px 24px 0px 24px',
  },
}));

const StyledDialogContent = styled(DialogContent)(() => ({
  '&.MuiDialogContent-root': {
    padding: '24px',
  },
}));

const StyledDialogActions = styled(DialogActions)(() => ({
  '&.MuiDialogActions-root': {
    padding: '0px 24px 24px 24px',
  },
}));

export default function CustomDialog(props: CustomDialogProps) {
  const {
    children,
    confirmDisabled,
    confirmText = 'Confirm',
    emit,
    fullWidthCancel,
    fullWidthConfirm,
    fullWidth,
    hideCancel,
    hideConfirm,
    hideTitle,
    loading,
    open,
    title,
  } = props;

  const renderTitle = (): ReactNode => {
    if (hideTitle) {
      return null;
    }

    return (
      <StyledDialogTitle variant="h5">
        <span>{title}</span>
        <IconButton onClick={emit?.close}>
          <Iconify icon={piccIcon.other.close} />
        </IconButton>
      </StyledDialogTitle>
    );
  };

  const renderActions = (): ReactNode => {
    if (hideCancel && hideConfirm) {
      return null;
    }

    return (
      <StyledDialogActions>
        {!hideCancel && (
          <Button
            fullWidth={fullWidthCancel}
            variant="outlined"
            disabled={loading}
            onClick={emit?.close}
            color="primary"
          >
            Cancel
          </Button>
        )}
        {!hideConfirm && (
          <LoadingButton
            variant="contained"
            fullWidth={fullWidthConfirm}
            loading={loading}
            disabled={confirmDisabled}
            onClick={emit?.confirm}
            color="primary"
          >
            {confirmText}
          </LoadingButton>
        )}
      </StyledDialogActions>
    );
  };

  return (
    <Dialog
      open={open}
      fullWidth={fullWidth}
      onClose={emit?.close}
    >
      {renderTitle()}
      <StyledDialogContent>
        {children}
      </StyledDialogContent>
      {renderActions()}
    </Dialog>
  );
}
