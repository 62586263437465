export function NavConfigCandidate() {
  const nonWorkspaceRoutes = {
    subheader: '',
    items: [
      // {
      //   title: 'Application _dev',
      //   path: paths.candidate.application.root(),
      //   icon: <Iconify icon="eos-icons:application-outlined" />,
      // },
    ],
  };

  const items: any = [];
  items.push(nonWorkspaceRoutes);
  return items;
}
