import services, {
  AppConfig,
  AppConfigWorkspace,
  CandidateUserAccountRegistrationRequest,
  Session,
  UserAccount,
  UserAccountInvitation,
  UserAccountRegistrationRequest,
} from '@piccadilly-cloud/connect-platform-services';

import { SessionTypes } from '../types';
import { setActiveWorkspace, setSession } from '../utils';

const loginUniversal = (
  token: string,
  email: string,
  password: string,
  rememberMe: boolean,
  vendor: string,
) => async (dispatch: React.Dispatch<any>) => {
  const session: Session = await services.edge.session.create({
    vendor,
    email,
    phoneNumber: '',
    password,
    appHost: 'auth.',
    rememberMe,
    token,
  })({});

  // setSession(session.token);

  dispatch({
    type: SessionTypes.LOGIN_UNIVERSAL,
    payload: {
      user: null,
      session,
    },
  });
};

const login = (
  token: string,
  email: string,
  password: string,
  rememberMe: boolean,
  vendor: string,
) => async (dispatch: React.Dispatch<any>) => {
  let defaultHost = 'piccadillylearn';
  if (vendor) {
    defaultHost = vendor;
  } else if (process.env.REACT_APP_DEFAULT_HOST) {
    defaultHost = process.env.REACT_APP_DEFAULT_HOST;
  }

  const session: Session = await services.edge.session.create({
    vendor: defaultHost,
    email,
    phoneNumber: '',
    password,
    appHost: defaultHost,
    rememberMe,
    token,
  })({});

  setSession(session.token);

  dispatch({
    type: SessionTypes.LOGIN,
    payload: {
      user: null,
      session,
    },
  });
};

const loginPasscode = (
  token: string,
  passcode: string,
  vendor: string,
) => async (dispatch: React.Dispatch<any>) => {
  let defaultHost = 'piccadillylearn';
  if (vendor) {
    defaultHost = vendor;
  } else if (process.env.REACT_APP_DEFAULT_HOST) {
    defaultHost = process.env.REACT_APP_DEFAULT_HOST;
  }

  const session: Session = await services.edge.session.createForPassCode({
    vendor: defaultHost,
    email: '',
    phoneNumber: '',
    password: '',
    appHost: defaultHost,
    rememberMe: true,
    token,
  }, passcode)({});

  setSession(session.token);

  dispatch({
    type: SessionTypes.LOGIN,
    payload: {
      user: null,
      session,
    },
  });
};

const logout = () => async (dispatch: React.Dispatch<any>) => {
  let currentHostname = window.location.hostname;
  currentHostname = currentHostname.replace('www.', '');
  if (currentHostname.endsWith('.ai')) {
    currentHostname = currentHostname.replace('.ai', '');
  } else if (currentHostname.endsWith('.com')) {
    currentHostname = currentHostname.replace('.com', '');
  }

  const session: Session = await services.edge.session.create({
    appHost: currentHostname,
    token: '',
    vendor: '',
    email: '',
    phoneNumber: '',
    password: '',
    rememberMe: false,
  })({});

  setSession(null);
  setActiveWorkspace(null);

  dispatch({
    type: SessionTypes.LOGOUT,
    payload: {
      session,
      isAuthenticated: false,
      isFailedToFetch: false,
    },
  });
};

// REGISTER
const register = (
  token: string,
  request: UserAccountRegistrationRequest,
  invitation: UserAccountInvitation,
) => async (dispatch: React.Dispatch<any>): Promise<Session> => {
  const creationRequest: UserAccountRegistrationRequest = {
    vendor: request.vendor,
    firstName: request.firstName,
    lastName: request.lastName,
    email: request.email,
    emailVerified: invitation.data.sendMethod === 'EMAIL',
    password: request.password,
    accountRole: request.accountRole,
    invitationId: invitation.id,
    workspaces: request.workspaces,
    behaviorOpts: {
      useGettingStarted: false,
      isUserZero: false,
      isGettingStartedComplete: false,
    },
  };

  return services.edge.register.userAccount(creationRequest)({ token })
    .then((nextSession) => {
      setSession(nextSession.token);
      dispatch({ type: SessionTypes.REGISTER, payload: { session: nextSession } });
      return nextSession;
    });
};
// REGISTER
const registerCandidate = (
  token: string,
  request: CandidateUserAccountRegistrationRequest,
) => async (dispatch: React.Dispatch<any>) => {
  const session = await services.edge.register.candidateUserAccount(request)({ token });

  setSession(session.token);

  dispatch({ type: SessionTypes.REGISTER, payload: { session } });
};

const updateCurrentAccount = (
  updatedAccount: UserAccount,
) => async (dispatch: React.Dispatch<any>) => {
  const payload = { userAccount: updatedAccount };
  dispatch({ type: SessionTypes.UPDATE_ACCOUNT, payload });
};

const updateCurrentAppConfig = (
  updatedAppConfig: AppConfig,
) => async (dispatch: React.Dispatch<any>) => {
  const payload = { appConfig: updatedAppConfig };
  dispatch({ type: SessionTypes.UPDATE_APP_CONFIG, payload });
};

const updateCurrentAppConfigWorkspaces = (
  updatedWorkspaces: AppConfigWorkspace[],
) => async (dispatch: React.Dispatch<any>) => {
  const payload = { workspaces: updatedWorkspaces };
  dispatch({ type: SessionTypes.UPDATE_APP_WORKSPACES, payload });
};

const setCurrentWorkspace = (index: number) => async (dispatch: React.Dispatch<any>) => {
  const payload = { workspaceIndex: index };
  dispatch({ type: SessionTypes.SET_ACTIVE_WORKSPACE, payload });
};

const authActions = {
  loginUniversal,
  login,
  loginPasscode,
  logout,
  register,
  registerCandidate,
  updateCurrentAccount,
  updateCurrentAppConfig,
  updateCurrentAppConfigWorkspaces,
  setCurrentWorkspace,
};

export default authActions;
