import { ContextMutations } from '../model';

import { OnboardingFlowAction, OnboardingFlowState } from './model';

const OnboardingFlowMutations: ContextMutations<OnboardingFlowState, OnboardingFlowAction> = {
  'onboardingFlow/SET_DISPATCH': (state, { dispatch }) => ({
    ...state,
    dispatch,
  }),
  'onboardingFlow/INIT_ONBOARDING': (state, { data, steps, isAvailable, welcomeDialog }) => ({
    ...state,
    data,
    steps,
    isAvailable,
    welcomeDialog,
  }),
  'onboardingFlow/STEP_COMPLETED': (state, { data, steps, isAvailable }) => ({
    ...state,
    data,
    steps,
    isAvailable,
  }),
  'onboardingFlow/TOGGLE_OPEN': (state, { isOpen }) => ({
    ...state,
    isOpen,
  }),
};

export const OnboardingFlowReducer = (state: OnboardingFlowState, action: OnboardingFlowAction): OnboardingFlowState =>
  OnboardingFlowMutations[action.type](state, action.payload as any) ?? state;
