import { Link, Typography } from '@mui/material';

import CustomDialog from './custom-dialog';

export interface PlansComingSoonDialogEmits {
  close?: () => void;
}

export interface PlansComingSoonDialogProps {
  open: boolean;
  emit?: PlansComingSoonDialogEmits;
}

export default function PlansComingSoonDialog(props: PlansComingSoonDialogProps) {
  const { open, emit } = props;

  return (
    <CustomDialog
      open={open}
      hideCancel
      hideConfirm
      emit={emit}
      title="Plans coming soon"
    >
      <Typography
        variant="body1"
        width={570}
      >
        Please <Link href="mailto:support@teqconnect.com">contact us</Link> for pricing.
      </Typography>
    </CustomDialog>
  );
}
