// routes
import Iconify from 'src/components/iconify';
import { paths } from 'src/routes/paths';
// components

// ----------------------------------------------------------------------

export default function NavConfigAdmin() {
  const manageRoutes = {
    subheader: 'Manage',
    items: [
      {
        title: 'Dashboard',
        path: paths.dashboard,
        icon: <Iconify icon="material-symbols:dashboard-outline-rounded" />,
      },
      {
        title: 'Users',
        path: paths.userManagement.root(),
        icon: <Iconify icon="material-symbols:home-work-outline-rounded" />,
        children: [
          {
            title: 'All Users',
            icon: <Iconify icon="material-symbols:account-tree-outline-rounded" />,
            path: paths.userManagement.allUsers(),
          },
          {
            title: 'Invite Users',
            icon: <Iconify icon="material-symbols:list-alt-add-outline-rounded" />,
            path: paths.userManagement.inviteUsers(),
          },
          {
            title: 'Pending Invitations',
            icon: <Iconify icon="material-symbols:airplane-ticket-outline-rounded" />,
            path: paths.userManagement.invitationsList(),
          },
        ],
      },
      {
        title: 'App Settings',
        path: paths.appSettings,
        icon: <Iconify icon="material-symbols:laptop-mac-outline" />,
      },
    ],
  };

  const appResourceRoutes = {
    subheader: '',
    items: [
      {
        title: 'Resources',
        path: paths.admin.resources.root(),
        icon: <Iconify icon="material-symbols:air-freshener-outline" />,
        children: [
          {
            title: 'Knowledge Base',
            icon: <Iconify icon="material-symbols:unknown-document-outline-rounded" />,
            path: process.env.REACT_APP_ZENDESK_HC_URL,
          },
          {
            title: 'Support',
            icon: <Iconify icon="material-symbols:contact-support-outline-rounded" />,
            path: paths.admin.resources.support(),
          },
        ],
      },
    ],
  };

  const experimentalRoutes = {
    subheader: '',
    items: [
      {
        title: 'Experimental',
        path: paths.admin.experimental.root(),
        icon: <Iconify icon="material-symbols:experiment-outline" />,
        children: [
          {
            title: 'Audio Upload',
            icon: <Iconify icon="material-symbols:audio-video-receiver-outline-rounded" />,
            path: paths.admin.experimental.audioUpload(),
          },
          {
            title: 'TEQ Processing',
            icon: <Iconify icon="material-symbols:audio-video-receiver-outline-rounded" />,
            path: paths.admin.experimental.teqProcessing(),
          },
        ],
      },
    ],
  };

  const items: any = [manageRoutes, appResourceRoutes, experimentalRoutes];
  return items;
}
