import services from '@piccadilly-cloud/connect-platform-services';

import { useCallback, useEffect, useState } from 'react';

import { useSessionContext } from 'src/contexts/session/useSessionContext';

/**
 * Hook to get a shareable link for a candidate scorecard
 * @param teqScoreId - The ID of the candidate scorecard
 * @param fetchOnInit - Whether to trigger the link generation on mount
 * @returns An object containing the shareable link, loading state, and a function to get the shareable link
 */
export const useShareableScorecardLink = (
  teqScoreId: string,
  fetchOnInit = false,
) => {
  const session = useSessionContext();
  const [shareableLink, setShareableLink] = useState('');

  const getShareableLink = useCallback(
    async (): Promise<string> => {
      let link = shareableLink;
      if (link) {
        return link;
      }

      try {
        const res = await services.edge.unauthLink.create({
          url: `https://${window.location.hostname.toLowerCase()}/candidate/results/share/${teqScoreId}`,
          vendor: session.account.vendor,
          token: session.token,
        })({});
        setShareableLink(res.id);
        link = res.id;
      } catch (error) {
        console.error(error);
      }

      return link;
    },
    [shareableLink, teqScoreId, session.account.vendor, session.token],
  );

  useEffect(() => {
    if (!fetchOnInit) {
      return;
    }
    getShareableLink();
  }, [getShareableLink, fetchOnInit]);

  return {
    shareableLink,
    getShareableLink,
  };
};
