import { UniformListingDefinition, UniformProfileDefinition } from '@piccadilly-cloud/connect-platform-services';

import { ContextAction, ContextDispatch } from '../model';

export enum ListingEditorFlowStepId {
  JOB = 'job',
  OVERVIEW = 'overview',
  AVAILABILITY = 'availability',
  ELIGIBILITY = 'eligibility',
  TEQ_INTERVIEW = 'teq-interview',
  PUBLISH = 'publish',
  SHARE = 'share',
}

export interface ListingEditorFlowStep {
  title: string;
  navStepTitle?: string;
  stepName: ListingEditorFlowStepId;
  stepSuccessMessage?: string;
}

export type ListingEditorFlowState = {
  currentStep: ListingEditorFlowStep;
  dispatch: ListingEditorFlowDispatch;
  isLoading: boolean;
  isSubmittedForReview: boolean;
  listing: UniformListingDefinition;
  profile: UniformProfileDefinition;
};

export type ListingEditorFlowAction =
  ContextAction<'listingEditorFlow/SET_DISPATCH', { dispatch: ListingEditorFlowDispatch }>
  | ContextAction<'listingEditorFlow/SET_IS_LOADING', { isLoading: boolean }>
  | ContextAction<'listingEditorFlow/SET_LISTING', { newListing: UniformListingDefinition }>
  | ContextAction<'listingEditorFlow/SET_PROFILE', { newProfile: UniformProfileDefinition }>
  | ContextAction<'listingEditorFlow/SET_IS_SUBMITTED_FOR_REVIEW', { isSubmittedForReview: boolean }>
  | ContextAction<'listingEditorFlow/SET_STEP', { step: ListingEditorFlowStep | undefined }>;

export interface ListingEditorFlowDispatch {
  'listingEditorFlow/deleteListing': (payload: {
    token: string;
    workspaceId: string;
    listingId: string;
  }) => Promise<void>;

  'listingEditorFlow/onNextStep': (payload: {
    currentStep: ListingEditorFlowStep;
  }) => Promise<void>;

  'listingEditorFlow/onPreviousStep': (payload: {
    currentStep: ListingEditorFlowStep;
  }) => Promise<void>;

  'listingEditorFlow/publishListing': (payload: {
    listingId: string;
    token: string;
    workspaceId: string;
  }) => Promise<void>;

  'listingEditorFlow/setCurrentStep': (stepId: ListingEditorFlowStepId) => Promise<void>;

  'listingEditorFlow/setIsLoading': (isLoading: boolean) => Promise<void>;

  'listingEditorFlow/submitListingForReview': (payload: {
    token: string;
    workspaceId: string;
    listingId: string;
    values: UniformListingDefinition;
  }) => Promise<void>;

  'listingEditorFlow/updateListing': (payload: {
    listingId: string;
    nextValues: UniformListingDefinition;
    token: string;
    workspaceId: string;
  }) => Promise<void>;
}

export type ListingEditorFlowDContextDispatch = ContextDispatch<ListingEditorFlowAction, ListingEditorFlowDispatch>;
