// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Image from 'src/components/image/image';
import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

export default function NavPoweredBy() {
  const settings = useSettingsContext();

  const isMini = settings.themeLayout === 'mini';

  return (
    <Stack
      sx={{
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box
          sx={{
            position: 'relative',
            pb: isMini ? 'unset' : 2,
          }}
        >
          <Image
            src={isMini ? '/logo/powered-by.svg' : '/logo/powered-by-full.svg'}
            alt="#"
            disabledEffect
            sx={{
              width: isMini ? '63px' : 'unset',
              height: isMini ? '60px' : 'unset',
            }}
          />
        </Box>
      </Stack>
    </Stack>
  );
}
