const alertBanner = {
  info: 'mdi:information',
};

const menu = {
  jobs: 'mdi:briefcase',
};

const other = {
  jobsListing: 'mdi:briefcase-search',
  jobDashboard: 'mdi:clipboard-pulse',
  applicationProfile: 'mdi:clipboard-account',
  downloadFile: 'mdi:tray-arrow-down',
  link: 'octicon:link-16',
  closeModal: 'mdi:close',
  close: 'ion:close-sharp',
  arrowRight: 'mdi:arrow-right-thin',
  chevronLeft: 'mdi:chevron-left',
  mapMarker: 'mdi:map-marker',
  workspace: 'mdi:monitor-dashboard',
  sort: 'carbon:chevron-sort',
};

const actions = {
  close: 'mdi:close-circle',
  delete: 'fa6-regular:trash-can',
  editDraft: 'mdi:text-box-edit',
  more: 'mdi:dots-vertical',
  add: 'mdi:plus-circle',
  favorite: 'mdi:star',
  unfavorite: 'mdi:star-outline',
  publish: 'material-symbols:publish',
  shareAssessment: 'fluent:mail-link-24-filled',
  duplicate: 'ion:duplicate-sharp',
  lock: 'mdi:lock',
  unlock: 'mdi:unlocked-variant',
  statusChange: 'ic:round-bolt',
};

const traits = {
  traits: 'material-symbols:widgets-rounded',
};

const transcription = {
  block: 'mdi:block',
  microphone: 'mdi:microphone',
  stop: 'material-symbols:stop-circle-outline-rounded',
};

const assessmentCredits = {
  creditsRemaining: 'mdi:clipboard-list',
  zeroRemaining: 'mdi:close-circle-outline',
};

const applicationIcons = {
  overview: 'mdi:cog',
  profile: 'mdi:account',
  availability: 'mdi:account-clock',
  background: 'mdi:clipboard-account',
  eligibility: 'mdi:shield-check',
  assessment: 'mdi:clipboard-clock',
  share: 'mdi:email-newsletter',
  agreementPolicy: 'mdi:shield-lock',
  assessmentCompleted: 'mdi:checkbox-marked',
  statusOpen: 'mdi:account-check',
  statusClosed: 'mdi:person-remove',
  statusHired: 'fa6-solid:handshake',
  note: 'mdi:text-box',
};

const jobStatus = {
  active: 'fluent:live-24-filled',
  closed: 'mdi:close-circle',
};

const candidateStatus = {
  interviewed: 'mdi:people',
  contacted: 'mdi:comment-check',
  overqualified: 'mdi:flag-variant',
};

const piccIcon = {
  actions,
  alertBanner,
  applicationIcons,
  candidateStatus,
  jobStatus,
  menu,
  other,
  traits,
  transcription,
  assessmentCredits,
};

export default piccIcon;
