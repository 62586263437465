import { useContext } from 'react';

import { SessionContext } from './SessionContext';

export const useSessionContext = () => {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error('useSessionContext context must be use inside AuthProvider');
  }

  return context;
};
