import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { LoadingScreen } from 'src/components/loading-screen';
import { paths } from 'src/routes/paths';

import { useSessionContext } from '../useSessionContext';

type CandidateGuardProps = {
  children: ReactNode;
};

export default function CandidateGuard({ children }: CandidateGuardProps) {
  const { isAuthenticated, isInitialized, account } = useSessionContext();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Navigate to={paths.auth.login} />;
  }

  if (account.accountRole !== 'CANDIDATE') {
    return <Navigate to={paths.notAuthorized} />;
  }

  return <>{children}</>;
}
