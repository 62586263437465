import { useContext } from 'react';

import { OnboardingFlowContext } from './OnboardingFlowContext';

export const useOnboardingFlowContext = () => {
  const context = useContext(OnboardingFlowContext);
  if (!context) {
    throw new Error('useOnboardingFlowContext context must be use inside OnboardingFlowProvider');
  }

  return context;
};
