import { useCallback, useEffect, useState } from 'react';

import pdfUtil from 'src/components-piccadilly/pdf/pdfUtil';

export const usePrint = () => {
  const [isReadyToExport, setIsReadyToExport] = useState(false);

  const onExport = useCallback(() => {
    setIsReadyToExport(true);
  }, []);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
        event.preventDefault();
        onExport();
      }
    },
    [onExport],
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, true);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  const calculatePadding = (index: number) => {
    if (index !== 0 && !pdfUtil.isSafari()) {
      return 10;
    }
    return 2;
  };

  return {
    isReadyToExport,
    setIsReadyToExport,
    onExport,

    calculatePadding,
  };
};
