import { useContext } from 'react';

import { CandidatePoolContext } from './CandidatePoolContext';
import { CandidatePoolState } from './model';

export const useCandidatePoolContext = (): CandidatePoolState => {
  const context = useContext(CandidatePoolContext);
  if (!context) {
    throw new Error('useCandidatePoolContext context must be use inside CandidatePoolContext');
  }

  return context;
};
