import { Box, ButtonBase, Divider, styled } from '@mui/material';

import { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Iconify from 'src/components/iconify/iconify';
import { Small } from 'src/components/typography';
import { useSessionContext } from 'src/contexts/session/useSessionContext';
import { useWorkspace } from 'src/hooks';
import { paths } from 'src/routes/paths';

import PopoverLayout from './popover-layout';

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  padding: 5,
  marginLeft: 4,
  borderRadius: '8px',
  '&:hover': { backgroundColor: theme.palette.action.hover },
}));

const StyledSmall = styled(Small)(({ theme }) => ({
  display: 'block',
  cursor: 'pointer',
  padding: '5px 1rem',
  '&:hover': { backgroundColor: theme.palette.action.hover },
}));

const WorkspacePopover: FC = () => {
  const session = useSessionContext();
  const workspaces = useWorkspace();
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledButtonBase disableRipple ref={anchorRef} onClick={() => setOpen(true)}>

        <Small ml={1} mr={0.5} color="text.secondary">
          <Small fontWeight="600" display="inline">
            {workspaces.index === -1 ? (
              <>Global Workspace</>
            ) : (
              <>{workspaces.workspace.name}</>
            )}

          </Small>
        </Small>
        <Box sx={{ fontSize: '0.25rem', mr: 0.5 }}>
          <Iconify icon="ph:caret-down" width="0.75rem" />
        </Box>
      </StyledButtonBase>

      <PopoverLayout
        hiddenViewButton
        // maxWidth={230}
        // minWidth={200}
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
        title="Workspaces"
      >
        <Box pt={1}>
          {/* <StyledSmall onClick={() => handleMenuItem('/dashboard/profile')}>Set Status</StyledSmall> */}

          {session.workspaces.map((ws, i) => (
            <StyledSmall
              key={`ws-dropdown-${ws.name}`}
              onClick={() => {
                session.setCurrentWorkspace(i);
                if (session.account.accountRole === 'RECRUITER') {
                  navigate(paths.recruiter.workspace.jobs.listings(i));
                } else {
                  navigate(paths.recruiter.workspace.dashboard(i));
                }
              }}
              sx={{
                fontWeight: workspaces.index === i ? '700' : 'unset',
              }}
            >
              {ws.name}
            </StyledSmall>
          ))}
          {session.workspaces.length === 0 && (
            <Small
              sx={{
                display: 'block',
                padding: '5px 1rem',
                color: 'text.disabled',
              }}
            >
              You currently do not have any workspaces
            </Small>
          )}

          {session.account.accountRole === 'WORKSPACE_ADMIN' && (
            <>
              <Divider sx={{ my: 1 }} />

              <StyledSmall
                onClick={() => {
                  navigate(paths.recruiter.workspace.new);
                }}
              >
                Create New
              </StyledSmall>
            </>
          )}
        </Box>
      </PopoverLayout>
    </>
  );
};

export default WorkspacePopover;
