import { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';

import GuestGuard from 'src/contexts/session/guards/guest-guard';
import SessionGuard from 'src/contexts/session/guards/session-guard';
import AuthClassicLayout from 'src/layouts/auth/classic';
import PrintClassicLayout from 'src/layouts/print/classic';
import UnauthenticatedLayout from 'src/layouts/unauthenticated';

import { RouteName } from '../model';

const LoginPage = lazy(() => import('src/pages/auth/login'));
const DemoExpirationPage = lazy(() => import('src/pages/demo-expiration-page'));
const LoginQueryPage = lazy(() => import('src/pages/auth/login-from-query'));
const LoginPasscodePage = lazy(() => import('src/pages/auth/login-passcode'));
const RegisterPage = lazy(() => import('src/pages/auth/register'));
// const RegisterSelectMethodPage = lazy(() => import('src/pages/auth/register-select-method'));
const RegisterCandidateApplication = lazy(() => import('src/pages/auth/register-candidate-application'));
const RegisterCandidateApplicationLanding = lazy(() => import('src/pages/auth/register-candidate-application-landing'));
const RegisterCreateAccountPage = lazy(() => import('src/pages/auth/register-create-account'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/forgot-password'));
const ForgotPasswordResetPage = lazy(() => import('src/pages/auth/forgot-password-reset'));
const VerifyAddressPage = lazy(() => import('src/pages/auth/verify-address'));

const PrivacyPolicyPage = lazy(() => import('src/pages/app/privacy-policy-page'));
const TermsOfServicePage = lazy(() => import('src/pages/app/terms-of-service-page'));

const Root = lazy(() => import('src/pages/root'));
const CandidateScorecardShare = lazy(() => import('src/pages/recruiter/candidates/candidate-scorecard-share'));

const Page404 = lazy(() => import('src/pages/404'));
const Page403 = lazy(() => import('src/pages/403'));

const global: RouteObject = {
  element: (
    <SessionGuard>
      <UnauthenticatedLayout>
        <Outlet />
      </UnauthenticatedLayout>
    </SessionGuard>
  ),
  children: [
    {
      path: 'privacy-policy',
      element: (<PrivacyPolicyPage />),
    },
    {
      path: 'terms-and-conditions',
      element: (<TermsOfServicePage />),
    },
  ],
};

const unauthenticated: RouteObject = {
  element: (
    <SessionGuard>
      <GuestGuard>
        <UnauthenticatedLayout>
          <Outlet />
        </UnauthenticatedLayout>
      </GuestGuard>
    </SessionGuard>
  ),
  children: [
    {
      path: 'register/candidate/application',
      element: (<RegisterCandidateApplication />),
    },

    {
      path: 'privacy-policy',
      element: (<PrivacyPolicyPage />),
    },
    {
      path: 'terms-of-service',
      element: (<TermsOfServicePage />),
    },
  ],
};

const unauthenticatedLinks: RouteObject = {
  element: (
    <SessionGuard>
      <PrintClassicLayout>
        <Outlet />
      </PrintClassicLayout>
    </SessionGuard>
  ),
  children: [
    {
      path: 'candidate/results/share/:teqScoreId',
      element: (<CandidateScorecardShare />),
    },
  ],
};

const auth: RouteObject = {
  element: (
    <SessionGuard>
      <GuestGuard>
        <AuthClassicLayout>
          <Outlet />
        </AuthClassicLayout>
      </GuestGuard>
    </SessionGuard>
  ),
  children: [
    {
      path: 'login',
      element: (<LoginPage />),
    },
    {
      path: 'signin',
      element: (<LoginPage />),
    },
    {
      path: 'auth',
      element: (<LoginQueryPage />),
    },
    {
      path: 'login/passcode',
      element: (<LoginPasscodePage />),
    },
    {
      path: 'signin/passcode',
      element: (<LoginPasscodePage />),
    },

    {
      path: 'register/access-code',
      element: (<RegisterPage />),
    },
    {
      path: 'register/create-account',
      element: (<RegisterCreateAccountPage />),
    },
    {
      path: 'forgot-password',
      element: (<ForgotPasswordPage />),
    },
    {
      path: 'assessment/:referrerId',
      element: (<RegisterCandidateApplicationLanding />),
    },
  ],
};

const verificationRoutes: RouteObject = {
  element: (
    <SessionGuard>
      <AuthClassicLayout>
        <Outlet />
      </AuthClassicLayout>
    </SessionGuard>
  ),
  children: [
    {
      path: 'forgot-password/reset',
      element: (<ForgotPasswordResetPage />),
    },
    {
      path: 'verify-address',
      element: (<VerifyAddressPage />),
    },
  ],
};

const core: RouteObject = {
  element: (
    <SessionGuard>
      <AuthClassicLayout>
        <Outlet />
      </AuthClassicLayout>
    </SessionGuard>
  ),
  children: [
    {
      id: RouteName.PREVIEW_EXPIRED,
      path: 'demo-expired',
      element: (<DemoExpirationPage />),
    },
    {
      path: '',
      element: (<Root />),
    },
    {
      path: '404',
      element: (<Page404 />),
    },
    {
      path: '403',
      element: (<Page403 />),
    },
  ],
};

export const mainRoutes = [
  global,
  unauthenticated,
  unauthenticatedLinks,
  auth,
  verificationRoutes,
  core,
];
