import { useEffect } from 'react';

interface ExternalRedirectProps {
  href: string;
}

const ExternalRedirect = ({ href }: ExternalRedirectProps) => {
  useEffect(() => {
    window.location.assign(href);
  });

  return null;
};

export default ExternalRedirect;
