import { IconButtonProps, iconButtonClasses } from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';

export function iconButton(theme: Theme) {
  const rootStyles = (_: IconButtonProps) => {
    const disabledState = {
      [`&.${iconButtonClasses.disabled}`]: {
        color: theme.palette.grey[300],
      },
    };

    return [disabledState];
  };

  return {
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: IconButtonProps }) => rootStyles(ownerState),
      },
    },
  };
}
