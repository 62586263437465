import { ReactNode, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { LoadingScreen } from 'src/components/loading-screen';
import { useDemoManager } from 'src/hooks';
import { paths } from 'src/routes/paths';

import { useSessionContext } from '../useSessionContext';

interface WorkspaceAdminGuardProps {
  children: ReactNode;
}

export default function WorkspaceAdminGuard({ children }: WorkspaceAdminGuardProps) {
  const {
    isAuthenticated,
    isInitialized,
    account,
    workspaces,
    activeWorkspace,
    setCurrentWorkspace,
  } = useSessionContext();
  const { hasDemoExpired, isDemoUser } = useDemoManager();
  const params = useParams();

  useEffect(() => {
    let index = -1;

    if (workspaces.length === 0) {
      console.info('environment missing workspaces');
      return;
    }

    // try and get the current index from the path param (aka we are in a workspace route)
    if (params.workspaceIndex) {
      try {
        index = parseInt(params.workspaceIndex || '-1', 10);
      } catch (err) {
        console.error(err);
      }
    }

    // fall back to the active workspace on the session
    if (index === -1) {
      if (activeWorkspace !== -1) {
        index = activeWorkspace;
      }
    }

    if (index === -1) {
      console.info('failed to determine an active workspace, falling back to global');
      return;
    }
    if (workspaces.length < index + 1) {
      index = 0;
    }

    if (activeWorkspace !== index) {
      setCurrentWorkspace(index);
    }
  }, [activeWorkspace, params.workspaceIndex, setCurrentWorkspace, workspaces.length]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Navigate to={paths.auth.login} />;
  }

  if (account.accountRole !== 'WORKSPACE_ADMIN') {
    return <Navigate to={paths.notAuthorized} />;
  }

  if (hasDemoExpired && isDemoUser) {
    return <Navigate to={paths.demoExpired} />;
  }

  return <>{children}</>;
}
