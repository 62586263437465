import services, { OnboardingDataResponse } from '@piccadilly-cloud/connect-platform-services';

import { OnboardingFlowContextDispatch, OnboardingFlowDispatch } from './model';

export const onboardingFlowDispatch: OnboardingFlowContextDispatch = (dispatch): OnboardingFlowDispatch => ({
  'onboardingFlow/initState': async ({
    data,
    steps,
    isAvailable,
    welcomeDialog,
  }) => {
    dispatch({
      type: 'onboardingFlow/INIT_ONBOARDING',
      payload: {
        data,
        steps,
        isAvailable,
        welcomeDialog,
      },
    });
  },
  'onboardingFlow/setStepComplete': async ({
    token,
    event,
    data,
    steps,
  }) => {
    if (data) {
      if (data.postRegistration.completedActions && data.postRegistration.completedActions.includes(event)) {
        return Promise.resolve();
      }

      const nextCompletedActions = [...data.postRegistration.completedActions, event];
      const nextSteps = steps.map((s) => s.event === event ? { ...s, isComplete: true } : s);
      const isComplete = nextSteps.every((s) => s.isComplete);
      const nextData: OnboardingDataResponse = {
        ...data,
        postRegistration: {
          ...data.postRegistration,
          completedActions: nextCompletedActions,
          isComplete,
        },
      };
      return services.edge.app.onboarding.update(nextData)({ token })
        .then(() => {
          dispatch({
            type: 'onboardingFlow/STEP_COMPLETED',
            payload: {
              data: nextData,
              steps: nextSteps,
              isAvailable: !isComplete,
            },
          });
        }).catch((err) => {
          console.error('failed to record onboarding event', err);
        });
    }
    return Promise.resolve();
  },
  'onboardingFlow/toggleOpen': async ({ isOpen }) => {
    dispatch({
      type: 'onboardingFlow/TOGGLE_OPEN',
      payload: {
        isOpen,
      },
    });
  },
});
