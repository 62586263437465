// @mui
import Box from '@mui/material/Box';
// auth
// routes
// hooks
// theme
// components

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function AuthBasicLayout({ children }: Props) {
  return (
    <Box>
      {children}
    </Box>
  );
}
