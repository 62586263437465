import { Avatar, AvatarProps, styled } from '@mui/material';

import { FC } from 'react';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  borderColor: theme.palette.common.white,
  borderWidth: 1,
  color: theme.palette.text.disabled,
  fontSize: '0.95rem',
}));

const AppAvatar: FC<AvatarProps> = (props) => <StyledAvatar {...props} />;

export default AppAvatar;
