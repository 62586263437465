import Box, { BoxProps } from '@mui/material/Box';
import Link from '@mui/material/Link';

import { forwardRef } from 'react';
// @mui

// routes
import { RouterLink } from 'src/routes/components';

import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx }, _) => {
    const settings = useSettingsContext();

    // OR using local (public folder)
    // -------------------------------------------------------
    const logo = (
      <Box
        component="img"
        src={settings.themeLayout === 'mini' ? '/logo/q-logo.svg' : '/logo/q-logo-full.svg'}
        sx={{
          height: 40,
          cursor: 'pointer',
          ...sx,
        }}
      />
    );
    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  },
);

export default Logo;
