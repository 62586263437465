import { useSnackbar } from 'notistack';

export type ToastMethod = (message: string) => void;

export interface ToastMethods {
  error: ToastMethod;
  info: ToastMethod;
  success: ToastMethod;
  warning: ToastMethod;
}

export const useToast = (): ToastMethods => {
  const { enqueueSnackbar } = useSnackbar();

  const error: ToastMethod = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' });
  };

  const info: ToastMethod = (message: string) => {
    enqueueSnackbar(message, { variant: 'info' });
  };

  const success: ToastMethod = (message: string) => {
    enqueueSnackbar(message, { variant: 'success' });
  };

  const warning: ToastMethod = (message: string) => {
    enqueueSnackbar(message, { variant: 'warning' });
  };

  return {
    error,
    info,
    success,
    warning,
  };
};
