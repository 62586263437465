/* eslint-disable react-hooks/rules-of-hooks */
import services, {
  Application,
  PublicListingForApplication,
  UniformProfileDefinition,
} from '@piccadilly-cloud/connect-platform-services';

import {
  Reducer,
  createContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import useLogger from 'src/utils/useReducerLogger';

import { ContextMutations } from '../model';
import { useSessionContext } from '../session/useSessionContext';

import {
  ApplicationFlowAction,
  ApplicationFlowDContextDispatch,
  ApplicationFlowDispatch,
  ApplicationFlowState,
} from './model';

type S = ApplicationFlowState;

const initState = (): S => ({
  application: undefined,
  dispatch: applicationFlowDispatch(() => { }),
  isEditing: false,
  isLoading: false,
  jobListing: undefined,
  jobProfile: undefined,
});

const applicationFlowMutations: ContextMutations<S, ApplicationFlowAction> = {
  'applicationFlow/SET_APPLICATION': (state, { application }) => ({
    ...state,
    application,
  }),

  'applicationFlow/SET_DISPATCH': (state, { dispatch }) => ({
    ...state,
    dispatch,
  }),

  'applicationFlow/SET_JOB_LISTING': (state, { jobListing }) => ({
    ...state,
    jobListing,
  }),

  'applicationFlow/SET_JOB_PROFILE': (state, { jobProfile }) => ({
    ...state,
    jobProfile,
  }),

  'applicationFlow/SET_IS_EDITING': (state, { isEditing }) => ({
    ...state,
    isEditing,
  }),

  'applicationFlow/SET_IS_LOADING': (state, { isLoading }) => ({
    ...state,
    isLoading,
  }),
};

const reducer = (
  state: S,
  action: ApplicationFlowAction,
): S => applicationFlowMutations[action.type](state, action.payload as any) ?? state;

const applicationFlowDispatch: ApplicationFlowDContextDispatch = (dispatch): ApplicationFlowDispatch => ({
  'applicationFlow/setApplication': async (payload: { application: Application | undefined }) => {
    dispatch({ type: 'applicationFlow/SET_APPLICATION', payload });
  },

  'applicationFlow/setJobListing': async (payload: { jobListing: PublicListingForApplication | undefined }) => {
    dispatch({ type: 'applicationFlow/SET_JOB_LISTING', payload });
  },

  'applicationFlow/setJobProfile': async (payload: { jobProfile: UniformProfileDefinition | undefined }) => {
    dispatch({ type: 'applicationFlow/SET_JOB_PROFILE', payload });
  },

  'applicationFlow/setIsEditing': async (payload: { isEditing: boolean }) => {
    dispatch({ type: 'applicationFlow/SET_IS_EDITING', payload });
  },

  'applicationFlow/setIsLoading': async (payload: { isLoading: boolean }) => {
    dispatch({ type: 'applicationFlow/SET_IS_LOADING', payload });
  },
});

type ApplicationFlowProviderProps = {
  children: React.ReactNode;
};

export const ApplicationFlowContext = createContext<S | null>(null);
export function ApplicationFlowProvider({ children }: ApplicationFlowProviderProps) {
  const [state, dispatch] = useReducer<Reducer<S, ApplicationFlowAction>>(
    process.env.REACT_APP_DEBUG_REDUX === 'true'
      ? useLogger(reducer)
      : reducer,
    initState(),
  );

  const session = useSessionContext();
  const params = useParams();
  const [applicationId] = useState(params.applicationId || '');
  const applicationDataQuery = useQuery(
    [`application-${session.account.email}`, {}],
    async () => {
      dispatch({ type: 'applicationFlow/SET_IS_LOADING', payload: { isLoading: true } });
      const data = await services.edge.candidate.application.edit(
        session.account.email,
        applicationId,
      )({ token: session.token });
      dispatch({ type: 'applicationFlow/SET_IS_LOADING', payload: { isLoading: false } });
      return data;
    },
    {
      keepPreviousData: false,
      cacheTime: 1,
      staleTime: Infinity,
      refetchOnMount: 'always',
      enabled: applicationId !== '',
    },
  );

  useEffect(() => {
    if (!applicationDataQuery.data) {
      return;
    }

    dispatch({
      type: 'applicationFlow/SET_APPLICATION',
      payload: { application: applicationDataQuery.data.application },
    });
    dispatch({
      type: 'applicationFlow/SET_JOB_LISTING',
      payload: { jobListing: applicationDataQuery.data.jobListing },
    });
    dispatch({
      type: 'applicationFlow/SET_JOB_PROFILE',
      payload: { jobProfile: applicationDataQuery.data.jobProfile },
    });
  }, [applicationDataQuery.data]);

  useEffect(() => {
    dispatch({
      type: 'applicationFlow/SET_DISPATCH',
      payload: { dispatch: applicationFlowDispatch(dispatch) },
    });
  }, [dispatch]);

  return (
    <ApplicationFlowContext.Provider value={state}>
      {children}
    </ApplicationFlowContext.Provider>
  );
}
