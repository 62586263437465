import { AppConfig, Session, UserAccount } from '@piccadilly-cloud/connect-platform-services';

const account: UserAccount = {
  id: '',
  vendor: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  lastSessionAt: new Date(),
  deleted: false,
  inactive: false,
  accountRole: 'GUEST',
  email: '',
  phoneNumber: '',
  personalInfo: {
    firstName: '',
    lastName: '',
    avatarUrl: '',
    companyName: '',
    jobTitle: '',
    joinDate: new Date(),
    phoneNumber: '',
  },
  analyticsInfo: {
    source: '',
    gender: '',
    age: '',
    ethnicity: '',
    extendedQuestions: [],
  },
  notificationOpts: {
    emailPreferred: false,
    emailVerified: false,
    smsPreferred: false,
    smsVerified: false,
    mfaEnabled: false,
  },
  workspaceSettings: {
    workspaces: [],
    defaultWorkspace: '',
  },
  behaviorSettings: {
    useGettingStarted: false,
    isGettingStartedComplete: false,
    isUserZero: false,
  },
  customerSettings: {
    hasCreatedStripeCustomer: false,
    hasActiveSubsubscription: false,
  },
};

const appConfig: AppConfig = {
  id: '',
  vendor: '',
  appHost: '',
  deleted: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  branding: {
    name: '',
    primaryLogoUrl: '',
    secondaryLogoUrl: '',
    landingHeroUrl: '',
  },
  behavior: {
    useDemoMode: false,
    genericCandidatePoolEnabled: true,
    candidateBehavior: {
      useDemographicCollection: false,
      useDemographicCollectionExtended: false,
    },
    recruiterBehavior: {
      useGenericProfiles: false,
      prequalifyCandidatesEnabled: false,
      assessmentPurchasesEnabled: false,
    },
  },
};

const session: Session = {
  account,
  appConfig,
  workspaces: [],
  sessionType: 'UNAUTHENTICATED',
  token: '',
};

const defaults = {
  account,
  appConfig,
  session,
};

export default defaults;
