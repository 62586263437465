import services from '@piccadilly-cloud/connect-platform-services';

import { useQuery } from 'react-query';

import { useSessionContext } from 'src/contexts/session/useSessionContext';

export const useFetchAssessmentAnswers = (
  accountId: string,
  applicationId: string,
  accessCode?: string,
) => {
  const session = useSessionContext();

  const fetchAnswers = accessCode
    ? services.edge.candidate.application.getAssessmentResponses_share(accountId, applicationId, accessCode)
    : services.edge.candidate.application.getAssessmentResponses_public(accountId, applicationId);

  const assessmentResponseQuery = useQuery(
    [`application-assessment-response-${accountId}-${applicationId}`, {}],
    () => fetchAnswers({ token: session.token }),
    {
      keepPreviousData: true,
      cacheTime: 10000,
      staleTime: Infinity,
      refetchOnMount: false,
    },
  );

  return {
    assessmentResponseQuery,
  };
};
