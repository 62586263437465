// @mui
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { SxProps, Theme } from '@mui/material/styles';

import { m } from 'framer-motion';

// components
import { varHover } from 'src/components/animate';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export default function SettingsButton(_: Props) {
  const settings = useSettingsContext();

  return (
    <Box>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        aria-label="settings"
        onClick={settings.onToggle}
      >
        <Iconify icon="ph:gear" width={24} />
      </IconButton>
    </Box>
  );
}
