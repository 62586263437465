export function NavConfigVendorAdmin() {
  const nonWorkspaceRoutes = {
    subheader: '',
    items: [
    ],
  };

  const items: any = [];
  items.push(nonWorkspaceRoutes);
  return items;
}
