import { useContext } from 'react';

import { ListingEditorFlowContext } from './ListingEditorFlowContext';

export const useListingEditorFlowContext = () => {
  const context = useContext(ListingEditorFlowContext);
  if (!context) {
    throw new Error('useListingEditorFlowContext must be used inside ListingEditorFlowContextProvider');
  }
  return context;
};
