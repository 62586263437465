import services, { DynamicVendorPublic } from '@piccadilly-cloud/connect-platform-services';

import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { useSessionContext } from 'src/contexts/session/useSessionContext';

const getIsEnterpriseVendor = (dynamicVendor: DynamicVendorPublic) => !!dynamicVendor.parentVendorId;

export const useDynamicVendor = () => {
  const session = useSessionContext();
  const [isEnterpriseVendor, setIsEnterpriseVendor] = useState(false);
  const [hasRemainingAssessments, setHasRemainingAssessments] = useState(true);
  const [dynamicVendor, setDynamicVendor] = useState<DynamicVendorPublic | null>(null);

  const dynamicVendorQuery = useQuery(
    [`dynamic-vendor-${session.appConfig.vendor}`, {}],
    () => services.edge.manage.getForVendor()({ token: session.token, vendor: session.appConfig.vendor }),
    { keepPreviousData: false, cacheTime: 1, staleTime: Infinity, refetchOnMount: 'always' },
  );

  useEffect(() => {
    if (!dynamicVendorQuery.data) {
      return;
    }

    setDynamicVendor(dynamicVendorQuery.data);
    setIsEnterpriseVendor(getIsEnterpriseVendor(dynamicVendorQuery.data));
    const remainingAssessments = dynamicVendorQuery.data.assessments.remaining;
    setHasRemainingAssessments(!!remainingAssessments && remainingAssessments > 0);
  }, [dynamicVendorQuery.data, isEnterpriseVendor]);

  return {
    dynamicVendor,
    hasRemainingAssessments,
    isEnterpriseVendor,
    isError: dynamicVendorQuery.isError,
    isLoading: dynamicVendorQuery.isLoading,
    refetch: dynamicVendorQuery.refetch,
  };
};
