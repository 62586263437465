/* eslint-disable react-hooks/rules-of-hooks */
import { Reducer, createContext, useEffect, useReducer } from 'react';

import useLogger from 'src/utils/useReducerLogger';

import { ContextMutations } from '../model';

import {
  CandidatePoolAction,
  CandidatePoolDispatch,
  CandidatePoolDispatchContextType,
  CandidatePoolState,
} from './model';

type S = CandidatePoolState;

const initState = (): S => ({
  dispatch: candidatePoolDispatch(() => { }),
  isClosedJob: false,
  listingId: '',
});

const candidatePoolMutations: ContextMutations<S, CandidatePoolAction> = {
  'candidatePool/SET_IS_CLOSED_JOB': (state, { isClosedJob }) => ({
    ...state,
    isClosedJob,
  }),

  'candidatePool/SET_DISPATCH': (state, { dispatch }) => ({
    ...state,
    dispatch,
  }),

  'candidatePool/SET_LISTING_ID': (state, { listingId }) => ({
    ...state,
    listingId,
  }),
};

const reducer = (state: S, action: CandidatePoolAction): S =>
  candidatePoolMutations[action.type](state, action.payload as any) ?? state;

const candidatePoolDispatch: CandidatePoolDispatchContextType = (dispatch): CandidatePoolDispatch => ({
  'candidatePool/setIsClosedJob': async (payload: { isClosedJob: boolean }) => {
    dispatch({ type: 'candidatePool/SET_IS_CLOSED_JOB', payload });
  },

  'candidatePool/setListingId': async (payload: { listingId: string }) => {
    dispatch({ type: 'candidatePool/SET_LISTING_ID', payload });
  },
});

type CandidatePoolProviderProps = {
  children: React.ReactNode;
};

export const CandidatePoolContext = createContext<S | null>(null);
export function CandidatePoolProvider({ children }: CandidatePoolProviderProps) {
  const [state, dispatch] = useReducer<Reducer<S, CandidatePoolAction>>(
    process.env.REACT_APP_DEBUG_REDUX === 'true'
      ? useLogger(reducer)
      : reducer,
    initState(),
  );

  useEffect(() => {
    dispatch({
      type: 'candidatePool/SET_DISPATCH',
      payload: { dispatch: candidatePoolDispatch(dispatch) },
    });
  }, [dispatch]);

  return (
    <CandidatePoolContext.Provider value={state}>
      {children}
    </CandidatePoolContext.Provider>
  );
}
