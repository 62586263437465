import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

import { memo } from 'react';

import { NavSectionHorizontal } from 'src/components/nav-section';
import { useNavConfig } from 'src/routes/navigation/useNavConfig';
import { bgBlur } from 'src/utils/cssStyles';

import { HeaderShadow } from '../_common';
import { HEADER } from '../config-layout';

function NavHorizontal() {
  const theme = useTheme();

  // const { user } = useMockedUser();

  const navData = useNavConfig();

  return (
    <AppBar
      component="nav"
      sx={{
        top: HEADER.H_DESKTOP_OFFSET,
      }}
    >
      <Toolbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <NavSectionHorizontal
          data={navData.data}
          config={navData.config}
        />
      </Toolbar>

      <HeaderShadow />
    </AppBar>
  );
}

export default memo(NavHorizontal);
