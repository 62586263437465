import { useTheme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

import { useResponsive } from 'src/hooks';

import { HEADER } from '../config-layout';

export default function Main({ children }: BoxProps) {
  const theme = useTheme();
  const lgUp = useResponsive('up', 'lg');
  const xsDown = useResponsive('down', 'xs');

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: theme.palette.background.paper,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        pt: `${HEADER.H_MOBILE}px`,
        ...(lgUp && {
          pt: `${HEADER.H_MOBILE}px`,
          pb: 5,
        }),
      }}
    >
      <Box sx={{ px: xsDown ? 0 : 'unset' }}>
        {children}
      </Box>
    </Box>
  );
}
