import services, { PlanResponse } from '@piccadilly-cloud/connect-platform-services';

import { lazy, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';

import { useSessionContext } from 'src/contexts/session/useSessionContext';
import { useWorkspace } from 'src/hooks';
import { PaymentDashboardLayout } from 'src/views/payments';

const OverviewAppView = lazy(() => import('src/pages/payments/app/view'));

export default function Payments() {
  const w = useWorkspace();
  const session = useSessionContext();
  const [paymentClientSecret, setPaymentClientSecret] = useState<string>('');
  const [planType, setPlanType] = useState<PlanResponse>({} as PlanResponse);
  const [paymentId, setPaymentId] = useState<string>('');

  // Define useQuery for fetching family profiles
  const allRoles = useQuery(
    [`family-profiles-${w.workspace.id}`],
    () =>
      services.edge.job.profile.getOptionsByJobTitle(w.workspace.id)({
        token: session.token,
      }),
    {
      keepPreviousData: false,
      cacheTime: 1,
      staleTime: Infinity,
      refetchOnMount: 'always',
      enabled: Boolean(paymentClientSecret), // Enable only when paymentClientSecret is set
    },
  );

  // Handle transaction creation in useEffect
  useEffect(() => {
    if (
      paymentClientSecret || !session.appConfig.behavior.recruiterBehavior.assessmentPurchasesEnabled
    ) {
      return;
    }

    (async () => {
      try {
        const transaction = await services.edge.billing.transaction.create()({
          token: session.token,
          vendor: session.appConfig.vendor,
        });

        const position = await services.edge.billing.plan.getPlanByName('Smart Fit')({
          token: session.token,
        });

        setPlanType(position);
        setPaymentClientSecret(transaction.clientSecret);
        setPaymentId(transaction.id);
      } catch (error) {
        console.error('Error creating transaction:', error);
      }
    })();
  }, [
    paymentClientSecret,
    session.token,
    session.appConfig.behavior.recruiterBehavior.assessmentPurchasesEnabled,
    session.appConfig.vendor,
  ]);

  return (
    <div style={{ position: 'fixed' }}>
      <Helmet>
        <title>Payments</title>
      </Helmet>
      {session.account.accountRole === 'WORKSPACE_ADMIN'
        && session.appConfig.behavior.recruiterBehavior.assessmentPurchasesEnabled && (
          <PaymentDashboardLayout clientSecret={paymentClientSecret}>
            <OverviewAppView id={paymentId} allRoles={allRoles?.data?.items || []} plan={planType} />
          </PaymentDashboardLayout>
        )}
    </div>
  );
}
