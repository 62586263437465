import { Box, Typography, useTheme } from '@mui/material';

import CustomDialog from './custom-dialog';
import { BaseDialogProps } from './types';

export default function SubscriptionRequiredDialog(props: BaseDialogProps) {
  const { emit, open } = props;
  const theme = useTheme();
  return (
    <CustomDialog
      open={open}
      hideCancel
      hideConfirm
      fullWidth
      title="Subscription required"
      emit={{
        close: emit?.close,
      }}
    >
      <Box>
        <Typography variant="body1" fontWeight={400}>
          Please{' '}
          <a
            href="mailto:support@teqconnect.com"
            style={{ color: theme.palette.primary.main }}
          >
            contact us
          </a>{' '}
          for pricing.
        </Typography>
      </Box>
    </CustomDialog>
  );
}
