import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { LoadingScreen } from 'src/components/loading-screen';
import AuthGuard from 'src/contexts/session/guards/auth-guard';
import VendorAdminGuard from 'src/contexts/session/guards/vendor-admin-guard';
// import DashboardLayoutUnjustified from 'src/layouts/workspace-unjustified';
import DashboardLayout from 'src/layouts/v2/dashboard/layout';

// ----------------------------------------------------------------------

const Dashboard = lazy(() => import('src/pages/dashboard'));
const Profile = lazy(() => import('src/pages/user-account/profile'));
const AppSettings = lazy(() => import('src/pages/app/app-settings'));

export const vendorAdminRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <VendorAdminGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </VendorAdminGuard>
      </AuthGuard>
    ),
    children: [
      { path: 'dashboard', element: <Dashboard />, index: true },
    ],
  },
  {
    path: '',
    element: (
      <AuthGuard>
        <VendorAdminGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </VendorAdminGuard>
      </AuthGuard>
    ),
    children: [
      { path: 'profile', element: <Profile />, index: true },
      { path: 'app-settings', element: <AppSettings />, index: true },
    ],
  },
];
