import { Box, Typography, useTheme } from '@mui/material';

import CustomDialog from './custom-dialog';

export interface RequestCreditsDialogEmits {
  close?: () => void;
}

export interface RequestCreditsDialogProps {
  open: boolean;
  emit?: RequestCreditsDialogEmits;
}

export default function RequestCreditsDialog(props: RequestCreditsDialogProps) {
  const { emit, open } = props;
  const theme = useTheme();
  return (
    <CustomDialog
      open={open}
      hideCancel
      hideConfirm
      title="Request more credits"
      emit={{
        close: emit?.close,
      }}
    >
      <Box>
        <Typography
          variant="body1"
          fontWeight={400}
          color={theme.palette.grey[700]}
        >
          Please reach out to your Client Success Manager to purchase more credits.
        </Typography>
      </Box>
    </CustomDialog>
  );
}
