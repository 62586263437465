import { Session, UserAccount } from '@piccadilly-cloud/connect-platform-services';

import { defaultWelcomeDialogConfig, getOnboardingFlowConfig, welcomeDialogConfigs } from './config';
import { OnboardingFlowType, OnboardingStep, WelcomeDialogConfig } from './model';

export const shouldUseOnboarding = (account: UserAccount): boolean => {
  const isUserEligibleForOnboarding = account.accountRole === 'RECRUITER'
    || account.accountRole === 'WORKSPACE_ADMIN';
  const hasValidUserAccountId = account.id !== '';

  return isUserEligibleForOnboarding && hasValidUserAccountId;
};

export const getStepsConfig = (session: Session, workspaceIndex: number): OnboardingStep[] => {
  let steps: OnboardingStep[] = [];
  const configs = getOnboardingFlowConfig(workspaceIndex);
  if (session.appConfig.behavior.useDemoMode) {
    steps = configs[OnboardingFlowType.PURCHASE];
  }
  if (session.account.behaviorSettings.useGettingStarted) {
    steps = configs[OnboardingFlowType.PREPURCHASE];
  }

  return steps;
};

export const getWelcomeDialog = (session: Session): WelcomeDialogConfig => {
  if (session.appConfig.behavior.useDemoMode) {
    return welcomeDialogConfigs[OnboardingFlowType.PURCHASE];
  }
  if (session.account.behaviorSettings.useGettingStarted) {
    return welcomeDialogConfigs[OnboardingFlowType.PREPURCHASE];
  }

  return defaultWelcomeDialogConfig;
};
