import { useSessionContext } from 'src/contexts';

export const useDemoManager = () => {
  const session = useSessionContext();

  const demoExpirationDate = new Date(session.appConfig.behavior.demoExpiresAt ?? 0).getTime();
  const hasExpired = demoExpirationDate < Date.now();
  const hasDemoExpired = session.appConfig.behavior.useDemoMode && hasExpired;
  const isDemoUser = session.account.accountRole === 'RECRUITER'
    || session.account.accountRole === 'WORKSPACE_ADMIN';

  return {
    hasDemoExpired,
    isDemoUser,
  };
};
