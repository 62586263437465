import services from '@piccadilly-cloud/connect-platform-services';

import { useQuery } from 'react-query';

import { useSessionContext } from 'src/contexts/session/useSessionContext';

export const useFetchScorecard = (
  teqScoreId: string,
  accessCode?: string,
) => {
  const session = useSessionContext();

  const fetchScorecard = accessCode
    ? services.edge.teq.score.getCandidateScorecardForLink(teqScoreId, accessCode)
    : services.edge.teq.score.getCandidateScorecard(teqScoreId, true);

  const candidateScorecardQuery = useQuery(
    [`application-${session.account.email}`, {}],
    () => fetchScorecard({ token: session.token }),
    {
      keepPreviousData: false,
      cacheTime: 1,
      staleTime: Infinity,
      refetchOnMount: 'always',
      enabled: teqScoreId !== '',
    },
  );

  return {
    candidateScorecardQuery,
  };
};
