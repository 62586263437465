// routes
import { Session } from '@piccadilly-cloud/connect-platform-services';

import { paths } from 'src/routes/paths';
// ----------------------------------------------------------------------

export const jwtDecode = (token: string): any => {
  const unencode = atob(token);
  const base64Url = unencode.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  let expiredTimer;

  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);
  expiredTimer = setTimeout(() => {
    localStorage.removeItem('piccadillyAccessToken');
    window.location.href = paths.auth.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('piccadillyAccessToken', accessToken);

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken);
    tokenExpired(exp);
  } else {
    localStorage.removeItem('piccadillyAccessToken');
  }
};

const activeWorkspaceKey = 'piccadillyActiveWorkspace';

export const setActiveWorkspace = (workspaceIndex: string | null) => {
  if (workspaceIndex) {
    localStorage.setItem(activeWorkspaceKey, workspaceIndex);
  } else {
    localStorage.removeItem(activeWorkspaceKey);
  }
};

export const getActiveWorkspace = (): number => {
  const stored = localStorage.getItem(activeWorkspaceKey);
  if (!stored) {
    return -1;
  }
  try {
    return parseInt(stored, 10);
  } catch (err) {
    localStorage.removeItem(activeWorkspaceKey);
  }
  return -1;
};

export const redirectToVendor = (session: Session) => {
  let url = session.appConfig.appHost;
  if (url.endsWith('.localhost')) {
    url = `http://${url}:8083/auth?token=${session.token}`;
  } else if (url.endsWith('.network')) {
    url = `https://${url}/auth?token=${session.token}`;
  } else if (url.endsWith('teqconnect')) {
    url = `https://${url}.com/auth?token=${session.token}`;
  }
  window.location.href = url;
};

export const isUniversalLogin = (): boolean => window.location.hostname.startsWith('auth.');

export const getAppHostUrl = (host: string): string => {
  const lowercaseHost = host.toLowerCase();
  const currentHostname = window.location.hostname.toLowerCase();
  const splitHostname = currentHostname.split('.');
  let authUrl = '';
  if (splitHostname.findIndex((i) => i === 'teqconnect') !== -1) {
    authUrl = `${lowercaseHost}.teqconnect`;
  } else if (splitHostname.findIndex((i) => i === 'dev') !== -1) {
    authUrl = `${lowercaseHost}.connect.dev.volker.network`;
  } else if (splitHostname.findIndex((i) => i === 'staging') !== -1) {
    authUrl = `${lowercaseHost}.connect.staging.volker.network`;
  } else if (splitHostname.findIndex((i) => i === 'localhost') !== -1) {
    authUrl = `${lowercaseHost}.localhost`;
  }
  return authUrl;
};
