import fileManager from './fileManager';
import transcription from './transcription';
import userAccount from './userAccount';

const ctrl = {
  fileManager,
  transcription,
  userAccount,
};

export default ctrl;
