import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import type { ButtonBaseProps } from '@mui/material/ButtonBase';
import ButtonBase from '@mui/material/ButtonBase';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { CustomPopover, usePopover } from 'src/components/custom-popover/v2';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { useSessionContext } from 'src/contexts';
import { paths } from 'src/routes/paths';
import { piccIcon } from 'src/utils';

export type WorkspacesPopoverProps = ButtonBaseProps & {
  data?: {
    id: string;
    name: string;
    index: number;
    logo: string;
    plan: string;
    isDemo?: boolean;
  }[];
};

export function WorkspacesPopover({ data = [], sx, ...other }: WorkspacesPopoverProps) {
  const session = useSessionContext();

  const navigate = useNavigate();

  const theme = useTheme();

  const popover = usePopover();

  const mediaQuery = 'sm';

  const [workspace, setWorkspace] = useState(data[0]);

  const handleChangeWorkspace = useCallback(
    (newValue: (typeof data)[0]) => {
      setWorkspace(newValue);
      session.setCurrentWorkspace(newValue.index);
      navigate(paths.recruiter.workspace.dashboard(newValue.index));
      popover.onClose();
    }, [popover, session, navigate]);

  useEffect(() => {
    if (!workspace && data.length === 1) {
      setWorkspace(data[0]);
    }
    if (workspace && (workspace.index !== session.activeWorkspace)) {
      setWorkspace(data[session.activeWorkspace]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, session.activeWorkspace]);

  return (
    <>
      <ButtonBase
        disableRipple
        onClick={popover.onOpen}
        sx={{
          py: 0.5,
          gap: { xs: 0.5, [mediaQuery]: 1 },
          ...sx,
          width: 220,
        }}
        {...other}
      >
        <Iconify icon={piccIcon.other.workspace} color={theme.palette.grey[400]} />

        <Box
          component="span"
          sx={{
            typography: 'subtitle2',
            color: theme.palette.grey[700],
            fontWeight: 700,
            display: {
              xs: 'none',
              [mediaQuery]: 'inline-flex',
              width: 220,
            },
          }}
        >
          {workspace?.name}
        </Box>
        <Iconify width={20} icon={piccIcon.other.sort} sx={{ color: 'text.disabled' }} />
      </ButtonBase>

      <CustomPopover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        slotProps={{ arrow: { placement: 'top-left' } }}
      >
        <MenuList sx={{ width: 240 }}>
          {data.map((option) => (
            <MenuItem
              key={option.id}
              selected={option.id === workspace?.id}
              onClick={() => handleChangeWorkspace(option)}
              sx={{ height: 48 }}
            >
              <Box component="span" sx={{ flexGrow: 1 }}>
                {option.name}
              </Box>
              {option.isDemo && (
                <Label color="info">Demo</Label>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </CustomPopover>
    </>
  );
}
