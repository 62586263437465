import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';

import { useEffect } from 'react';

// @mui

// hooks
import Iconify from 'src/components/iconify';
import Logo from 'src/components/logo';
import { NavSectionVertical } from 'src/components/nav-section';
import Scrollbar from 'src/components/scrollbar';
import { useSessionContext } from 'src/contexts/session/useSessionContext';
import { usePathname, useResponsive, useWorkspace } from 'src/hooks';
// components
//
import { useNavConfig } from 'src/routes/navigation/useNavConfig';
import { paths } from 'src/routes/paths';

import { NavToggleButton } from '../_common';
import NavPoweredBy from '../_common/nav-powered-by';
import { NAV } from '../config-layout';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const session = useSessionContext();
  const ws = useWorkspace();

  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavConfig();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 0, mb: 2 }} />

      <NavSectionVertical
        data={navData.data}
        config={navData.config}
      />

      <Box sx={{ flexGrow: 1 }} />

      <NavSectionVertical
        data={[{
          subheader: '',
          items: [
            {
              title: 'Users',
              path: paths.recruiter.members.root(ws.index),
              icon: <Iconify icon="mdi:account-multiple" />,
            },
            {
              title: 'Support',
              path: process.env.REACT_APP_ZENDESK_HC_URL || '',
              icon: <Iconify icon="mdi:help-circle" />,
            },
          ],
        }]}
      />

      <NavPoweredBy />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
        mt: session.appConfig.behavior.useDemoMode ? '54px' : 'unset',
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
