import type { SxProps, Theme } from '@mui/material/styles';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Main } from './main';

// ----------------------------------------------------------------------

export type DashboardLayoutProps = {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  clientSecret: string;
};

export function PaymentDashboardLayout({ children, clientSecret }: DashboardLayoutProps) {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '');

  return (
    <>
      {clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <Main isNavHorizontal={false}>{children}</Main>
        </Elements>
      )}
    </>
  );
}
