import services from '@piccadilly-cloud/connect-platform-services';

import { Reducer, createContext, useEffect, useReducer } from 'react';
import { useQuery } from 'react-query';

import { useWorkspace } from 'src/hooks';
import useLogger from 'src/utils/useReducerLogger';

import { useSessionContext } from '../session';

import { defaultOnboardingData, defaultWelcomeDialogConfig } from './config';
import { onboardingFlowDispatch } from './dispatch';
import { getStepsConfig, getWelcomeDialog, shouldUseOnboarding } from './helpers';
import { OnboardingFlowAction, OnboardingFlowState } from './model';
import { OnboardingFlowReducer } from './reducer';

type S = OnboardingFlowState;

const initState = (): S => ({
  data: defaultOnboardingData,
  steps: [],
  dispatch: onboardingFlowDispatch(() => { }),
  isAvailable: false,
  isOpen: true,
  welcomeDialog: defaultWelcomeDialogConfig,
});

interface OnboardingFlowProviderProps {
  children: React.ReactNode;
}

export const OnboardingFlowContext = createContext<S | null>(null);

const reducer = OnboardingFlowReducer;

export function OnboardingFlowProvider({ children }: OnboardingFlowProviderProps) {
  const session = useSessionContext();
  const w = useWorkspace();

  const [state, dispatch] = useReducer<Reducer<S, OnboardingFlowAction>>(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    process.env.REACT_APP_DEBUG_REDUX === 'true' ? useLogger(reducer) : reducer,
    initState(),
  );

  useEffect(() => {
    dispatch({
      type: 'onboardingFlow/SET_DISPATCH',
      payload: {
        dispatch: onboardingFlowDispatch(dispatch),
      },
    });
  }, [dispatch]);

  const onboardingEnabled = shouldUseOnboarding(session.account);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onboardingQuery = useQuery(
    [`onboarding-data-${session.account.email}`, {}],
    () => services.edge.app.onboarding.getByAccountId(session.account.email)({ token: session.token }),
    {
      keepPreviousData: false,
      cacheTime: 1,
      staleTime: Infinity,
      refetchOnMount: 'always',
      enabled: onboardingEnabled,
    },
  );

  useEffect(() => {
    if (onboardingEnabled && onboardingQuery.data) {
      const steps = getStepsConfig(session, w.index).map((step) => ({
        ...step,
        isComplete: onboardingQuery.data.postRegistration.completedActions.includes(step.event),
      }));

      const welcomeDialog = getWelcomeDialog(session);

      dispatch({
        type: 'onboardingFlow/INIT_ONBOARDING',
        payload: {
          data: onboardingQuery.data,
          steps,
          isAvailable: onboardingEnabled
            && !onboardingQuery.data.postRegistration.isComplete
            && steps.length > 0,
          welcomeDialog,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingEnabled, onboardingQuery.data, session, w.index]);

  return (
    <OnboardingFlowContext.Provider value={state}>
      {children}
    </OnboardingFlowContext.Provider>
  );
}
