import services, {
  AssessmentUpdateType,
  DynamicVendor,
  DynamicVendorAssessmentUpdateRequest,
} from '@piccadilly-cloud/connect-platform-services';

import {
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import { useState } from 'react';

import { useSessionContext } from 'src/contexts';
import { useLoading, useToast } from 'src/hooks';
import { fNumber } from 'src/utils/format-number';

import CustomDialog from './custom-dialog';

export interface UpdateAssessmentsDialogEmits {
  close?: () => void;
  confirm?: () => void;
}

export interface UpdateAssessmentsDialogProps {
  dynamicVendor: DynamicVendor;
  updateType: AssessmentUpdateType;
  remaining: number;
  open: boolean;
  emit?: UpdateAssessmentsDialogEmits;
}

export default function UpdateAssessmentsDialog(props: UpdateAssessmentsDialogProps) {
  const { dynamicVendor, emit, open, remaining, updateType } = props;
  const session = useSessionContext();
  const theme = useTheme();
  const toast = useToast();
  const loading = useLoading();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedAssessments, setSelectedAssessments] = useState<number>(0);

  const isIncrease = updateType === AssessmentUpdateType.INCREASE;
  const title = `${isIncrease ? 'Add additional' : 'Remove'} assessments`;
  const confirmText = `${isIncrease ? 'Add' : 'Remove'} assessments`;
  const remainingCount = isIncrease
    ? fNumber(remaining)
    : fNumber(dynamicVendor.assessments.remaining);
  const remainingText = `${remainingCount} remaining`;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === '') {
      setSelectedAssessments(0);
      setErrorMessage('');
      return;
    }

    const numericValue = Number(value);
    if (Number.isNaN(numericValue)) {
      setErrorMessage('Please enter a valid number.');
      return;
    }

    if (!isIncrease && numericValue > dynamicVendor.assessments.remaining) {
      setSelectedAssessments(numericValue);
      setErrorMessage('You cannot remove more assessments than remaining.');
    } else {
      setSelectedAssessments(numericValue);
      setErrorMessage('');
    }
  };

  const handleAddAssessments = async (): Promise<void> => {
    try {
      loading.start();
      const req: DynamicVendorAssessmentUpdateRequest = {
        updateType,
        amount: selectedAssessments,
      };
      await services.edge.manage.updateAssessments(
        dynamicVendor.vendorId,
        req,
      )({
        token: session.token,
        vendor: session.appConfig.vendor,
      });
      toast.success(`Successfully ${isIncrease ? 'added' : 'removed'} assessments.`);
      emit?.confirm?.();
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong. Please try again.');
    } finally {
      loading.stop();
      setSelectedAssessments(0);
    }
  };

  const handleClose = () => {
    setErrorMessage(null);
    setSelectedAssessments(0);
    emit?.close?.();
  };

  return (
    <CustomDialog
      confirmDisabled={loading.isLoading || !selectedAssessments || !!errorMessage}
      confirmText={confirmText}
      hideCancel
      fullWidthConfirm
      loading={loading.isLoading}
      open={open}
      title={title}
      emit={{
        close: handleClose,
        confirm: handleAddAssessments,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        minWidth="550px"
        gap={2}
      >
        <Typography
          variant="body2"
          fontWeight={600}
        >
          {isIncrease
            ? 'You may add additional assessments.'
            : 'You may remove all assessments.'}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          columnGap={1}
          alignItems="flex-start"
        >
          <Box
            display="flex"
            flexDirection="column"
          >
            <FormControl>
              <TextField
                size="small"
                value={selectedAssessments}
                label="Assessments*"
                onChange={handleInputChange}
                error={!!errorMessage}
                helperText={errorMessage}
              />
            </FormControl>
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
              lineHeight: 1.4,
              mt: 1,
            }}
          >
            {remainingText}
          </Typography>
        </Box>
        {!isIncrease && (
          <Box>
            <Button
              variant="text"
              onClick={() => {
                setSelectedAssessments(dynamicVendor.assessments.remaining);
              }}
              size="small"
              sx={{ color: theme.palette.primary.main }}
            >
              Remove all
            </Button>
          </Box>
        )}
      </Box>
    </CustomDialog>
  );
}
