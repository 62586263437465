import { Box, Button, Typography, useTheme } from '@mui/material';

import { PlansComingSoonDialog } from 'src/components';
import { useSessionContext } from 'src/contexts';
import { useBoolean } from 'src/hooks';

export default function GlobalBanner() {
  const session = useSessionContext();
  const theme = useTheme();
  const isPlansDialogOpen = useBoolean();

  if (!session.appConfig.behavior.useDemoMode) {
    return undefined;
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '54px',
          top: 0,
          backgroundColor: theme.palette.primary.darker,
          position: 'fixed',
          zIndex: 1299,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100%' }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            columnGap={1}
          >
            <Typography
              variant="h6"
              color={theme.palette.background.paper}
            >
              Unlock full workspace features with a subscription.
            </Typography>
            <Button
              color="success"
              variant="contained"
              size="small"
              onClick={isPlansDialogOpen.onTrue}
            >
              View plans
            </Button>
          </Box>
        </Box>
      </Box>
      <PlansComingSoonDialog
        open={isPlansDialogOpen.value}
        emit={{
          close: isPlansDialogOpen.onFalse,
        }}
      />
    </>
  );
}
